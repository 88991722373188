import { FC, useEffect, useState } from 'react';
import { ButtonContainer, HeaderContainer } from './styles';
import { Button, Modal, Table, toastify, Typography } from '#ui-kit';
import {
  ButtonSize,
  ButtonVariant,
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';
import { notAvailableValue } from '#utils';
import debug from '#services/debug.service';
import { RestaurantsService } from '#services/restaurants';
import { RestaurantSelector } from '../RestaurantSelector';
import { GetRestaurantsDto, RestaurantResponse } from 'types';

type FranchisorShopRestaurantsContainerProps = {
  franchisorShopId: number;
};

export const FranchisorShopRestaurantsContainer: FC<
  FranchisorShopRestaurantsContainerProps
> = ({ franchisorShopId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [restaurants, setRestaurants] = useState<RestaurantResponse[]>([]);
  const restaurantsService = new RestaurantsService();

  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(0);

  const [restaurantsToAdd, setRestaurantsToAdd] = useState<
    RestaurantResponse[]
  >([]);
  const [isRestaurantSelectorOpen, setRestaurantSelectorOpen] =
    useState<boolean>(false);
  const [restaurantsToAddPage, setRestaurantsToAddPage] = useState<number>(1);
  const [restaurantsToAddSize, setRestaurantsToAddSize] = useState<number>(10);
  const [restaurantsToAddTotal, setRestaurantsToAddTotal] = useState<number>(0);

  const handleCloseRestaurantSelector = () => setRestaurantSelectorOpen(false);

  const onPageChangeRestaurantsToAdd = (
    pageNumber: number,
    pageSize: number,
  ) => {
    setRestaurantsToAddPage(pageNumber);
    setRestaurantsToAddSize(pageSize);
  };

  const onPageChange = (pageNumber: number, pageSize: number) => {
    setPage(pageNumber);
    setSize(pageSize);
  };

  const getRestaurantsToAdd = async () => {
    try {
      const params: GetRestaurantsDto = {
        page: String(restaurantsToAddPage),
        size: String(restaurantsToAddSize),
        isHasNoFranchisorShop: true,
        includeArchived: true,
      };

      const response = await restaurantsService.getRestaurants(params);

      setRestaurantsToAdd((old) => {
        if (page === 1) {
          return response.data;
        } else {
          return [...old, ...response.data];
        }
      });

      setRestaurantsToAddTotal(Math.ceil(response.total / response.size));
    } catch (e) {
      debug.error('🧑🏻‍💻 ~ getRestaurants ~ err:', e);
    } finally {
      setIsLoading(false);
    }
  };

  const getRestaurants = async () => {
    try {
      setIsLoading(true);

      const params: GetRestaurantsDto = {
        page: String(page),
        size: String(size),
        franchisorShopIds: [franchisorShopId],
        includeArchived: true,
      };

      const response = await restaurantsService.getRestaurants(params);

      setRestaurants(response.data);

      setTotalPages(Math.ceil(response.total / response.size));
    } catch (e) {
      debug.error('🧑🏻‍💻 ~ getRestaurants ~ err:', e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenRestaurantSelector = async () => {
    await getRestaurantsToAdd();

    setRestaurantSelectorOpen(true);
  };

  const handleSelectRestaurantToAdd = async (restaurantId: string) => {
    try {
      await restaurantsService.updateRestaurant(restaurantId, {
        franchisorShopId,
      });

      await getRestaurantsToAdd();
      await getRestaurants();
      toastify.success('Ресторан успешно привязан');
    } catch (error) {
      debug.error('🧑🏻‍💻 ~ updateRestaurant ~ err:', error);

      toastify.error('Не удалось привязать ресторан');
    }
  };

  const handleDelete = async (restaurantId: string) => {
    try {
      setIsLoading(true);

      await restaurantsService.updateRestaurant(restaurantId, {
        franchisorShopId: null,
      });

      await getRestaurants();

      toastify.success('Ресторан успешно отвязан');
    } catch (e) {
      debug.error('🧑🏻‍💻 ~ updateRestaurant ~ err:', e);
      toastify.error('Не удалось отвязать ресторан');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRestaurantsToAdd();
  }, [page, size]);

  useEffect(() => {
    getRestaurants();
  }, [page, size]);

  return (
    <>
      <Modal
        isOpen={isRestaurantSelectorOpen}
        onClose={handleCloseRestaurantSelector}
      >
        <RestaurantSelector
          totalPages={restaurantsToAddTotal}
          currentPage={restaurantsToAddPage}
          restaurants={restaurantsToAdd}
          onClose={handleCloseRestaurantSelector}
          onPageChange={onPageChangeRestaurantsToAdd}
          onSelect={handleSelectRestaurantToAdd}
          isLoading={isLoading}
        />
      </Modal>
      <HeaderContainer>
        <Typography
          size={TypographySize.xl}
          tag={TypographyTag.span}
          style={{ marginTop: 40, marginBottom: 20 }}
        >
          Рестораны
        </Typography>
        <ButtonContainer>
          <Button
            label="Привязать новый ресторан"
            variant={ButtonVariant.Primary}
            onClick={handleOpenRestaurantSelector}
            size={ButtonSize.Small}
            isLoading={isLoading}
          />
        </ButtonContainer>
      </HeaderContainer>
      <Table
        {...{
          loading: isLoading,
          onPageChange,
          currentPage: page,
          totalPages,
          header: {
            cells: {
              0: {
                width: 232,
                align: TableCellAlign.Left,
                children: (
                  <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                    Наименование
                  </Typography>
                ),
              },
              1: {
                width: 232,
                align: TableCellAlign.Left,
                children: (
                  <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                    Адрес ресторана
                  </Typography>
                ),
              },
              2: {
                width: 232,
                align: TableCellAlign.Left,
              },
            },
          },
          rows: restaurants?.map(({ id, name, address }) => ({
            cells: {
              0: {
                to: `/restaurants/${id}`,
                width: 232,
                align: TableCellAlign.Left,
                onClick: () => {},
                children: (
                  <Typography
                    size={TypographySize.s}
                    tag={TypographyTag.span}
                    weight={TypographyWeight.Light}
                  >
                    {notAvailableValue(name)}
                  </Typography>
                ),
              },
              1: {
                to: `/restaurants/${id}`,
                width: 232,
                align: TableCellAlign.Left,
                onClick: () => {},
                children: (
                  <Typography
                    size={TypographySize.s}
                    tag={TypographyTag.span}
                    weight={TypographyWeight.Light}
                  >
                    {notAvailableValue(address)}
                  </Typography>
                ),
              },
              2: {
                width: 232,
                align: TableCellAlign.Left,
                onClick: () => {},
                children: (
                  <ButtonContainer>
                    <Button
                      label="Отвязать"
                      variant={ButtonVariant.Error}
                      onClick={() => handleDelete(id)}
                      size={ButtonSize.Small}
                      isLoading={isLoading}
                    />
                  </ButtonContainer>
                ),
              },
            },
          })),
        }}
      />
    </>
  );
};
