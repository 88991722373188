import * as Styled from './styles';
import { Button, Table, Typography } from '#ui-kit';
import {
  ButtonSize,
  ButtonVariant,
  TableCellAlign,
  TypographySize,
  TypographyTag,
  TypographyWeight,
} from '#ui-kit.types';
import { StatusBadge } from '#components';

import { formatPhoneNumber, notAvailableValue } from '#utils';

import { TableProps } from './types/Table.types';
import { RestaurantStatusBadgeMap } from 'pages/Restaurants/constants';
import { RestaurantStatusEnum } from 'types';

const Component: React.FC<TableProps> = ({
  loading,
  data: { data },
  onRestaurantUpdate,
}) => (
  <Table
    {...{
      loading,
      header: {
        cells: {
          0: {
            width: 232,
            align: TableCellAlign.Left,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Наименование
              </Typography>
            ),
          },
          1: {
            width: 232,
            align: TableCellAlign.Left,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Адрес
              </Typography>
            ),
          },
          2: {
            width: 232,
            align: TableCellAlign.Left,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Номер телефона
              </Typography>
            ),
          },
          3: {
            width: 232,
            align: TableCellAlign.Left,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                ИНН
              </Typography>
            ),
          },
          4: {
            width: 232,
            align: TableCellAlign.Left,
            children: (
              <Typography size={TypographySize.xs} tag={TypographyTag.span}>
                Статус
              </Typography>
            ),
          },
          5: {
            width: 232,
          },
        },
      },
      rows: data.map(({ id, name, address, phoneNumber, tin, status }) => ({
        // to: `/restaurants/${id}`,
        cells: {
          0: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.s}
                tag={TypographyTag.span}
                weight={TypographyWeight.Light}
              >
                {notAvailableValue(name)}
              </Typography>
            ),
          },
          1: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.s}
                tag={TypographyTag.span}
                weight={TypographyWeight.Light}
              >
                {notAvailableValue(address)}
              </Typography>
            ),
          },
          2: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.s}
                tag={TypographyTag.span}
                weight={TypographyWeight.Light}
              >
                {formatPhoneNumber(notAvailableValue(phoneNumber))}
              </Typography>
            ),
          },
          3: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Typography
                size={TypographySize.s}
                tag={TypographyTag.span}
                weight={TypographyWeight.Light}
              >
                {notAvailableValue(tin)}
              </Typography>
            ),
          },
          4: {
            width: 232,
            align: TableCellAlign.Left,
            onClick: () => {},
            children: (
              <Styled.RestaurantStatusContainer>
                <StatusBadge<typeof RestaurantStatusEnum>
                  {...{
                    value: status,
                    data: RestaurantStatusBadgeMap,
                  }}
                />
              </Styled.RestaurantStatusContainer>
            ),
          },
          5: {
            width: 232,
            align: TableCellAlign.Left,
            children: (
              <Button
                {...{
                  label:
                    status === RestaurantStatusEnum.archived
                      ? 'Разархивировать'
                      : 'Архивировать',
                  size: ButtonSize.Medium,
                  type: 'button',
                  variant: ButtonVariant.Primary,
                  disabled: loading,
                  onClick: () =>
                    onRestaurantUpdate(
                      id,
                      status === RestaurantStatusEnum.archived
                        ? RestaurantStatusEnum.open
                        : RestaurantStatusEnum.archived,
                    ),
                }}
              />
            ),
          },
        },
      })),
    }}
  />
);

export default Component;
