import { useEffect, useState } from 'react';

import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import debug from '#services/debug.service';
import { RestaurantsService } from '#services/restaurants';
import { UseRestaurantsReturnProps } from './types/use-restaurants.types';
import { RestourantsResponse, UpdateRestaurantRequestDto } from 'types';

const useRestaurants = (): UseRestaurantsReturnProps => {
  const restaurantsService = new RestaurantsService();

  const [data, setData] = useState<RestourantsResponse>();
  const [isLast, setIsLast] = useState(true);

  const [page, setPage] = useState<number>(1);
  const [{ generalLoading, loading }, setLoading] = useState({
    generalLoading: true,
    loading: false,
  });

  const getRestaurants = async (reset = false) => {
    if (reset) {
      setPage(1);
      setData(undefined);
      setIsLast(false);
    }

    setLoading((old) => ({
      ...old,
      loading: true,
    }));
    try {
      const result = await restaurantsService.getRestaurants({
        page: `${reset ? 1 : page}`,
        size: '20',
        includeArchived: true,
      });

      setData((prevState) => {
        if (reset || !prevState) {
          return result;
        } else {
          return {
            ...prevState,
            data: [...prevState.data, ...result.data],
          };
        }
      });

      setIsLast(result.isLast);

      setPage((prevPageNumber) => prevPageNumber + 1);

      setLoading((old) => ({
        ...old,
        generalLoading: false,
        loading: false,
      }));
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ useRestaurants ~ getRestaurants ~ err:', err);
    }
  };

  const updateRestaurant = async (
    restaurantId: string,
    dataToUpd: UpdateRestaurantRequestDto,
  ) => {
    await restaurantsService.updateRestaurant(restaurantId, dataToUpd);

    await getRestaurants(true);
  };

  useEffect(() => {
    getRestaurants();
  }, []);

  useBottomScrollListener(() => {
    if (!generalLoading && !loading && !isLast) {
      getRestaurants();
    }
  });

  return [
    data,
    { generalLoading, loading },
    isLast,
    getRestaurants,
    updateRestaurant,
  ];
};

export default useRestaurants;
