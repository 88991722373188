import { useRestaurants } from '#hooks';

import { toastify } from '#ui-kit';
import { Head, LoadingContent } from '#components';
import { HeadUnderlineVariant } from '#components.types';

import Table from './containers/Table';
import { RestaurantStatusEnum } from 'types';

export default () => {
  const [
    data,
    { generalLoading, loading },
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    isLast,
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    getRestaurants,
    updateRestaurant,
  ] = useRestaurants();
  if (generalLoading || !data) {
    return <LoadingContent />;
  }

  const handleUpdateRestaurant = async (
    restaurantId: string,
    status: RestaurantStatusEnum,
  ) => {
    try {
      await updateRestaurant(restaurantId, {
        status,
      });

      toastify.success(
        `Ресторан успешно ${
          status === RestaurantStatusEnum.archived
            ? 'архивирован'
            : 'разархивирован'
        }`,
      );
    } catch {
      toastify.error(
        `Не удалось ${
          status === RestaurantStatusEnum.archived
            ? 'архивировать'
            : 'разархивировать'
        } ресторан`,
      );
    }
  };

  return (
    <>
      <Head
        {...{
          label: 'Рестораны',
          underlineVariant: HeadUnderlineVariant.Primary,
        }}
      />
      <Table
        {...{ data, loading, onRestaurantUpdate: handleUpdateRestaurant }}
      />
    </>
  );
};
