import { StatusBadgeVariant } from '#components.types';
import { RestaurantStatusEnum } from 'types';

export const RestaurantStatusBadgeMap = {
  [RestaurantStatusEnum.archived]: {
    label: 'Архивирован',
    variant: StatusBadgeVariant.Disabled,
  },
  [RestaurantStatusEnum.closed]: {
    label: 'Временно закрыт',
    variant: StatusBadgeVariant.Warning,
  },
  [RestaurantStatusEnum.disabled]: {
    label: 'Деактивирован',
    variant: StatusBadgeVariant.Blocked,
  },
  [RestaurantStatusEnum.open]: {
    label: 'Открыт',
    variant: StatusBadgeVariant.Active,
  },
};
