import styled from 'styled-components';
import { ptr } from '#styles.utils';

export const RestaurantStatusContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  .status-badge {
    min-width: ${ptr(140)};
  }
`;
