import { useEffect, useState } from 'react';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import * as Styled from './styles';
import { ptr } from '#styles.utils';
import { colors } from '#styles.variables';
import { Typography } from '#ui-kit';
import { TypographySize, TypographyTag } from '#ui-kit.types';
import debug from '#services/debug.service';
import { PromotionsService } from '#services/promotions';
import { GetPromotionsProductsResponse } from '#services/promotions/responses/get-promotions-products.response';
import { RestaurantsService } from '#services/restaurants';
import ProductCard from './components/ProductCard';
import { TableViewProps } from './types/Table.types';
import { RestourantsResponse } from 'types';
import { formatPrice } from 'utils/formatPrice';

const TableView: React.FC<TableViewProps> = ({ promotionId }) => {
  const promotionsService = new PromotionsService();
  const restaurantsService = new RestaurantsService();
  const [data, setData] = useState<RestourantsResponse>();
  const [isLast, setIsLast] = useState(true);
  const [page, setPage] = useState<number>(1);

  const [selectedRestaurantId, setSelectedRestaurantId] = useState<string>('');
  const [promoData, setPromoData] =
    useState<GetPromotionsProductsResponse | null>(null);

  const getPromotionProducts = async (restaurantId: string) => {
    try {
      setSelectedRestaurantId(restaurantId);
      const response = await promotionsService.getPromotionsProducts({
        promotionId,
        restaurantId,
      });
      setPromoData(response);
    } catch (e) {
      setPromoData(null);
    }
  };

  const getRestaurants = async () => {
    try {
      const result = await restaurantsService.getRestaurants({
        page: `${page}`,
        size: '20',
      });
      setData((prevState) => {
        if (prevState) {
          return {
            ...prevState,
            data: [...prevState.data, ...result.data],
          };
        }

        return result;
      });

      setIsLast(result.isLast);
      setPage((prevPageNumber) => prevPageNumber + 1);
    } catch (err) {
      debug.error('🧑🏻‍💻 ~ useUsers ~ getUsers ~ err:', err);
    }
  };

  const getRestaurantsWhileScrolling = () => {
    if (!isLast) {
      getRestaurants();
    }
  };

  useEffect(() => {
    getRestaurants();
  }, []);

  useEffect(() => {
    if (!!data?.data.length) {
      const firstAvailableRestaurantId = data.data.find(
        (item) => item.status,
      )?.id;
      if (firstAvailableRestaurantId) {
        getPromotionProducts(firstAvailableRestaurantId);
      }
    }
  }, [data]);
  return (
    <div>
      {!!data?.data.length ? (
        <>
          <Styled.FlexContainer>
            <Styled.HeaderItem>
              <Typography size={TypographySize.m} tag={TypographyTag.span}>
                Ресторан
              </Typography>
            </Styled.HeaderItem>
            <Styled.HeaderItem>
              <Typography size={TypographySize.m} tag={TypographyTag.span}>
                Продукты
              </Typography>
            </Styled.HeaderItem>
          </Styled.FlexContainer>
          <Styled.FlexContainer>
            <Styled.ContainerItem>
              <BottomScrollListener onBottom={getRestaurantsWhileScrolling}>
                {(scrollRef) => (
                  <Styled.RestaurantsContainer
                    ref={scrollRef as React.RefObject<HTMLDivElement>}
                    style={{ overflowY: 'auto', direction: 'rtl' }}
                  >
                    {data?.data.map((item) => (
                      <Styled.RestaurantItem
                        onClick={() => {
                          setPromoData(null);
                          getPromotionProducts(item.id);
                        }}
                        style={{
                          background:
                            selectedRestaurantId === item.id
                              ? colors.primary._100
                              : colors.black._00,
                          border:
                            selectedRestaurantId === item.id
                              ? 'none'
                              : `${ptr(0.5)} solid ${colors.black._05}`,
                        }}
                      >
                        <p
                          className="filter_item_text"
                          style={{
                            color:
                              selectedRestaurantId === item.id
                                ? colors.black._00
                                : colors.black._80,
                          }}
                        >
                          {item.name}
                        </p>
                      </Styled.RestaurantItem>
                    ))}
                  </Styled.RestaurantsContainer>
                )}
              </BottomScrollListener>
            </Styled.ContainerItem>
            <Styled.ContainerItem>
              {(promoData?.oldPrice ||
                promoData?.price ||
                !promoData?.items.length) && (
                <Styled.PriceContainer>
                  {promoData?.price ? (
                    <Typography
                      size={TypographySize.m}
                      tag={TypographyTag.span}
                      className="price"
                    >
                      Стоимость:{' '}
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        className="price"
                        color={colors.primary._100}
                      >
                        {formatPrice(promoData?.price)} ₽
                      </Typography>
                    </Typography>
                  ) : null}
                  {promoData?.oldPrice ? (
                    <Typography
                      size={TypographySize.m}
                      tag={TypographyTag.span}
                      className="price"
                    >
                      Прежняя стоимость:{' '}
                      <Typography
                        size={TypographySize.m}
                        tag={TypographyTag.span}
                        className="price"
                        color={colors.error._100}
                      >
                        {formatPrice(promoData?.oldPrice)} ₽
                      </Typography>
                    </Typography>
                  ) : null}
                  {!promoData?.items.length ? (
                    <Typography
                      style={{ textAlign: 'center' }}
                      size={TypographySize.m}
                      tag={TypographyTag.span}
                    >
                      Продукты отсутствуют
                    </Typography>
                  ) : null}
                </Styled.PriceContainer>
              )}
              {!!promoData?.items.length ? (
                <Styled.ProductsContainer>
                  {promoData?.items.map((item) => (
                    <ProductCard data={item} />
                  ))}
                </Styled.ProductsContainer>
              ) : null}
            </Styled.ContainerItem>
          </Styled.FlexContainer>
        </>
      ) : null}
    </div>
  );
};

export default TableView;
